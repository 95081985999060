import React from 'react'
import { useIntl } from 'react-intl'

import { useGasAppCalls } from '../../../hooks'
import { Box, Typography, withStyles } from '@material-ui/core'
import { budgetStepStyles } from '../../gas-app-steps/budget/budget-step.styles'
import { useModalContext } from '../../../../../ui'
import { ChangeInstallerDialog } from '../../dialogs/change-installer-dialog/change-installer-dialgog'

export const GetInstallerTxt = withStyles(budgetStepStyles)(({ classes, model }) => {
  const { getInstaller } = useGasAppCalls()
  const { formatMessage } = useIntl()
  const {
    actions: { open },
  } = useModalContext()

  const haveInstaller = model['empresa_ca'] !== null && model['empresa'] !== null

  const handleGetInstaller = () => {
    const payload = { solicitud_id: model.id }
    getInstaller(payload)
      .then((response) => {
        window.location.replace(
          `https://solicitargasnatural.nedgia.es/configuracion-vivienda/?root=3&id=${response.data.uuid}`
        )
      })
      .catch((response) => console.log(response))
  }

  const handleRedirectWP = (observations) => {
    const payload = { solicitud_id: model.id, observaciones_cambio_instalador: observations }
    getInstaller(payload)
      .then((response) => {
        window.location.replace(
          `https://solicitargasnatural.nedgia.es/configuracion-vivienda/?root=3&id=${response.data.uuid}`
        )
      })
      .catch((response) => console.log(response))
  }

  const handleOpenDialog = () => {
    open({
      Component: ChangeInstallerDialog,
      data: { model, handleRedirectWP },
      type: 'centered',
      closable: true,
    })
  }

  return (
    <>
      <Box display="flex">
        <Typography variant="body2">
          {formatMessage({ id: 'pages.budget.view.panel1.detail.changeInstaller.title' })}
        </Typography>
        <span
          onClick={haveInstaller ? handleOpenDialog : handleGetInstaller}
          className={classes.downloadLink}
          style={{ marginLeft: '0.5em' }}
        >
          <Typography variant="body2">
            {formatMessage({ id: 'pages.budget.view.panel1.detail.changeInstaller.click.title' })}
          </Typography>
        </span>
      </Box>
    </>
  )
})

import React from 'react'
import { useIntl } from 'react-intl'

import { ThemeButton } from '../../../../../ui'
import { useGasAppCalls } from '../../../hooks'

export const GetInstallerBtn = ({ model }) => {
  const { getInstaller } = useGasAppCalls()
  const payload = { solicitud_id: model.id }
  const { formatMessage } = useIntl()
  const handleRedirectWP = () => {
    getInstaller(payload)
      .then((response) => {
        window.location.replace(
          `https://solicitargasnatural.nedgia.es/configuracion-vivienda/?root=3&id=${response.data.uuid}`
        )
      })
      .catch((response) => console.log(response))
  }

  return (
    <ThemeButton labelM="large" color="primary" onClick={handleRedirectWP}>
      {formatMessage({ id: 'global.getInstaller' })}
    </ThemeButton>
  )
}
